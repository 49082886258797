<template>
  <div id="app">
    <div class="container">
      <div class="container_logo">
        <img
          src="./assets/logo-verde.png"
          alt=""
          style="height: 15rem; width: 20rem"
        />
      </div>

      <router-view></router-view>
    </div>

    <footer>Global Fibra</footer>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.container_logo {
  display: flex;
  justify-content: center;
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 80px;
  width: 100%;

  color: #f7f1f1;
  background-color: #005663;
}
</style>
